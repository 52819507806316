import React, { useContext, useEffect } from 'react';
import SEO from '@/components/seo';
import { graphql, Link, PageProps } from 'gatsby';
import { Context } from '@/components/Layout';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import hoverCircle from '@/components/Interaction/hoverCircle';

import * as pageHeader from '@/styles/object/page-header.module.scss';
import * as styles from '@/styles/page/contact.module.scss';
import * as pageHeaderDetail from '@/styles/object/page-header-detail.module.scss';

const ContactAgreement: React.FC<PageProps<GatsbyTypes.ContactAgreementQuery>> =
  ({ data }) => {
    const loading = useContext(Context);
    useEffect(() => {
      hoverCircle();
    }, []);
    return (
      !loading && (
        <>
          <SEO title={`${data.microcmsContact.title} | お問い合わせ`} />
          <div className="page-wrap --common">
            <div className="page-wrap__wave" />
            <div className="page-wrap__bg" />

            <div className="page-contents-box">
              <header
                className={pageHeaderDetail.page_header_detail}
                style={{ border: `0`, paddingTop: `10rem`, paddingBottom: `3rem` }}
              >
                <h1 className={pageHeaderDetail.page_header_detail__title}>
                  {data.microcmsContact.title}
                </h1>
              </header>

              <div className="section">
                <p className="text-01">
                  当社Webサイト上の「お問い合わせ」、「お申し込み」のフォームのご利用に際し、「個人情報の取扱い」について、あらかじめご同意くださいますようお願いします。
                </p>
              </div>

              <div className="section">
                <h3 className="title-lv4">
                  当Webサイト上の「お問い合わせ」、「お申し込み」における個人情報の取扱い
                </h3>
                <ul className="list-rect">
                  <li className="list-rect__item">
                    当Webサイト上の「お問い合わせ」、「お申し込み」のフォームのご利用により、当社に提供される個人情報は、「
                    <a href="/privacy/" className={styles.contact__link}>
                      個人情報の取扱いについて
                    </a>
                    」に従います。
                  </li>
                  <li className="list-rect__item">
                    お問い合わせ、お申し込み対応に際し、必須項目を入力してください。入力事項が不正確である場合や入力内容によっては、お返事を差し上げられない場合があります。
                  </li>
                  <li className="list-rect__item">
                    ご入力いただいた個人情報は、本件における対応のみに利用いたします。
                  </li>
                  <li className="list-rect__item">
                    本件における対応に際し、一部の業務を委託することがあります。
                  </li>
                  <li className="list-rect__item">
                    開示等のご請求についても、お問い合わせフォームにてお受けいたします。
                  </li>
                  <li className="list-rect__item">
                    お問い合わせ、お申し込みの前に、当社の「
                    <a href="/privacy/" className={styles.page_header__text}>
                      個人情報の取扱いについて
                    </a>
                    」を必ずご一読ください。
                  </li>
                </ul>
              </div>
              <div className={`${styles.contact__btn} section`}>
                <Link
                  to={`/contact/form/${data.microcmsContact.contactId}/`}
                  state={{ agreement: data.microcmsContact.contactId }}
                  className="button-02 js-hover-circle"
                  data-circle-type="reverse-button"
                  data-circle-inner="arrow-right-orange"
                  style={{ color: `#fff` }}
                >
                  個人情報の取扱いに同意する
                </Link>
              </div>
            </div>
          </div>
          <UIBreadcrumb
            UpperItem={[[`Contact`, `/contact`]]}
            CurrentItem={data.microcmsContact.title}
          />
        </>
      )
    );
  };

export default ContactAgreement;

export const query = graphql`
  query ContactAgreement($ContactPageId: String!) {
    microcmsContact(contactId: { eq: $ContactPageId }) {
      title
      contactId
      display
    }
  }
`;
